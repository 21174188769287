import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const personmodal = document.getElementById('personmodal')
    document.querySelectorAll('.person-card-link').forEach(function(item) {
      item.addEventListener('click', function() {
        const person_id = item.getAttribute("id")
        fetch('/team', { headers: { accept: "application/json" } })
        .then(response => response.json())
        .then((data) => {
          data.team.map((person) => {
            let array = []
            if (person.id.toString() === person_id) {
              array.push(`
                <div class="personmodal-container text-dark" id="personmodal">
                  <div class="personmodal-image">   
                    <img src="${person.photo}" alt="${person.first_name}_photo"/>
                  </div> 
                  
                  <div class="personmodal-details">
                    <h2 class="modal-title-subs" id="modal-title">${person.first_name} ${person.last_name}</h2>
    
                    <div class="modal-body-subs">
                        <p>${person.title}</p>
                        <p>${person.phone_number ? person.phone_number : "--"}</p>
                        <a id="personmodal-email" href="mailto:${person.email}">${person.email ? person.email : " "}</a>
                        <p class="bio">${person.bio}</p>
                        <a href="${person.linkedin}" target="_blank">
                          <div class="home-icon person-icon" style="background: #CA2A4C; padding: 2vh; border-radius: 50%; width: 6vh;">
                            <svg id="linkedin" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" class="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                          </div> 
                        </a>
                    </div>
                  </div>
                </div> 
              `) 
              personmodal.innerHTML = array.join("")   
            }   
          })
        }) 
      })
    })
  }
}