import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "loadingBar" ]
        
    initialize() {
      this.hitCounter = 0
      this.switchTracker = true
    }

    connect() {
       console.log("Research scroll controller!")
    }

    scroll(event) {
      const scrolLevel = this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)
      if(scrolLevel > 0.8 && this.switchTracker) {
        this.switchTracker = false
        this.hitCounter += 1
        let q = ""

        this.loadingBarTarget.classList.toggle("d-none")

        if(this.element.dataset.searchQuery != '[]') {   
          q = `&search_query=${this.element.dataset.searchQuery ? this.element.dataset.searchQuery : "" }`
        } 

        let url = `/my_research?counter=${this.hitCounter}${q}`
        fetch(url, { headers: {'Accept': 'text/plain' }})
        .then(response => response.text())
        .then((data) => {
            this.loadingBarTarget.classList.toggle("d-none")
            this.element.insertAdjacentHTML("beforeend", data)
            this.switchTracker = true;
        })
      }
    }
}