
const bookmarkArticle = () => {
    const marks = document.querySelectorAll(".marks");

    marks.forEach((mark) => {
        mark.addEventListener("click", () => {

            if(mark.classList.contains('bookmarks')) {
                fetch(`https://www.independentresearchforum.com/articles/${mark.dataset.id}/likes`, {
                    method: 'POST',
                    headers: { 'Accept': 'text/plain'}
                })
                .then((response) => response.text())
                .then((data) => {

                    if(data == "false") {
                        alert("We are unable to perform this action at this time. Please try again later.")
                    } else {
                        mark.dataset.id = data 
                        mark.innerHTML = `<i class="fa-solid fa-bookmark" style="font-size: 28px; color: #CA2A4C;"></i>
                                          <div class="help-window">Remove from bookmarked articles</div>
                                         `
                        mark.classList.remove("bookmarks");
                        mark.classList.add("unbookmarks");
                    }
                });


            } else {
                fetch(`https://www.independentresearchforum.com/likes/${mark.dataset.id}`, {
                    method: 'DELETE',
                    headers: { 'Accept': 'text/plain'}
                })
                .then((response) => response.text())
                .then((data) => {

                    if(data == "false") {
                        alert("We are unable to perform this action at this time. Please try again later.")
                    } else {
                        mark.dataset.id = data 
                        mark.innerHTML = `<i class="fa-regular fa-bookmark" style="font-size: 28px; color: #CA2A4C;"></i>
                                        <div class="help-window">Add to bookmarked articles</div>
                                        `
                        mark.classList.remove("unbookmarks");
                        mark.classList.add("bookmarks");
                    }
                });

            }
        })
    });
}


export { bookmarkArticle }

