const previuosEvents = () => {
    const btn = document.querySelector(".previous-btn")
    const upcomingBtn = document.querySelector(".upcoming-btn")    
    const container = document.querySelector(".events-search-container")
    const form = document.querySelector(".events-search-form")
    const field = document.querySelector(".events-search-input")
    const submitBtn = document.querySelector(".events-search-button")
    
    // search all events
    if(form) {
      form.addEventListener("submit", (event) => {
          event.preventDefault()
          let url = `${form.action}?previous_search=${field.value}`

          if(window.innerWidth < 768) {
              url = `${url}&mobile=true`
          }
          
          container.innerHTML = `<div style="height: 100vh;">
                                  <div style="margin: 60px auto 0 auto; width: 20vw; text-align: center;">
                                      <div class="spinner-border" role="status" style="width: 8vw; height: 8vw;">
                                          <span class="visually-hidden"></span>
                                      </div>
                                  </div>
                                  <p style="margin: 20px auto 0 auto; width: 20vw; text-align: center;">LOADING EVENTS</p>
                                  </div>`

          fetch(url, { headers: { 'Accept': 'text/plain'  } })
          .then(response => response.text())
          .then((data) => {
              container.innerHTML = data
              submitBtn.disabled = false
              form.reset()
              upcomingBtn.classList.remove("active")
              btn.classList.remove("active")
          })
      })
    }

    // previous events
    if(btn) {
        btn.addEventListener("click", () => {
            btn.classList.add("active")
            upcomingBtn.classList.remove("active")
            const url= `${form.action}?filtered_events=previous`            
        
            ajaxCallback(url, container)
        })
    }

    // upcoming events
    if(upcomingBtn) {
        upcomingBtn.addEventListener("click", () => {
            upcomingBtn.classList.add("active")
            btn.classList.remove("active")
            const url= `${form.action}?filtered_events=upcoming`            

            ajaxCallback(url, container)
        })
    }
};

const ajaxCallback = (url, container) => {
  if(window.innerWidth < 768) {
      url = `${url}&mobile=true`
  }
  
  container.innerHTML = `<div style="height: 100vh;">
                          <div style="margin: 60px auto 0 auto; width: 20vw; text-align: center;">
                            <div class="spinner-border" role="status" style="width: 8vw; height: 8vw;">
                              <span class="visually-hidden"></span>
                            </div>
                          </div>
                          <p style="margin: 20px auto 0 auto; width: 20vw; text-align: center;">LOADING EVENTS</p>
                          </div>`
  
  fetch(url, { headers: { 'Accept': 'text/plain'  } })
  .then(response => response.text())
  .then((data) => {
      container.innerHTML = data
  })
}

export { previuosEvents }