import React, { useState } from "react";

const CutButton = ({ provider, edition }) => {
  const [toggleState, setToggleState] = useState("unclicked");

  return (
    <div className="" >
      <a
        className={
          toggleState === "clicked" ? "clicked" : "request-research-button"
        }
        href={"/research_provider/" + provider.id + "?edition=" + edition.id}
        onClick={() => setToggleState("clicked")}
        style={{color: '#CA2A4C'}}
      >
        Request Introduction
      </a>

      {toggleState === "clicked" ? (
        <p>
          Thank you for requesting an introduction for {provider}. Our team will
          get back to you shortly.
        </p>
      ) : null}
    </div>
  );
};

export default CutButton;
